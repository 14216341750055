import React from 'react';

// import doc from google
const Resume = () => (
  <div
    style={{
      border: 'ridge',
      display: 'block',
      margin: 'auto',
      padding: '50',
      width: '60%',
    }}
  >
    <iframe
      style={{
        border: 'none',
        width: '100%',
        height: '70%',
      }}
      src="https://docs.google.com/document/d/e/2PACX-1vTW6jN4w_eGwFCkbjkEpQGj5iTzTp4DUevPpxPS-Bybzq010Mj75HhPm37_V-W1Az003GAlwduwXnoi/pub?embedded=true"
    ></iframe>
  </div>
);

Resume.customStyles = {
  App: {
    height: '100%',
    minHeight: '100%',
    paddingTop: 10,
  },
};

export default Resume;

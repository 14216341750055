import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { array, func, number, object, string } from 'prop-types';

const propTypes = {
  currentTab: number,
  handleChange: func,
  pageLabels: array,
  styles: object,
  navColor: string,
};

const defaultProps = {
  currentTab: 0,
  handleChange: () => {},
  pageLabels: [],
  styles: {},
  navColor: 'transparent'
};

const NavBar = ({ currentTab, handleChange, pageLabels, styles, navColor }) => {
  const { Tabs: TabsStyle, Tab: TabStyle, AppBar: AppBarStyle } = styles || {};
  return (
    <AppBar
      elevation={0}
      color={navColor}
      sx={{ ...{ paddingBottom: 1 }, ...AppBarStyle }}
    >
      <Toolbar>
        <Tabs
          sx={{ ...{ width: '100%' }, ...TabsStyle }}
          variant={'standard'}
          centered={true}
          value={currentTab}
          onChange={handleChange}
          aria-label="Navigation"
        >
          {pageLabels.map((label) => (
            <Tab
              sx={{ ...{ color: '100%' }, ...TabStyle }}
              key={label}
              aria-label={label}
              label={label}
            />
          ))}
        </Tabs>
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = propTypes;
NavBar.defaultProps = defaultProps;

export default NavBar;

import React from 'react';

import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import profilePic from '../../../assets/profile.jpeg';

const textColor = '#bdbdbd';

const Splash = () => (
  <div style={{ backgroundColor: 'black' }}>
    <Fade in timeout={1500}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          margin: 0,
          padding: 0,
          display: 'flex',
          width: 'auto',
          height: '100%',
          backgroundSize: 'cover',
          WebkitBackgroundSize: 'cover',
          textAlign: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/background-full.png)',
          boxShadow: '0px -5px 8px 0px black inset',
        }}
      >
        <Grid item sx={{ display: 'block' }}>
          <Box sx={{ typography: 'h4', color: textColor }}>
            Torie&nbsp;Jenkins, Software&nbsp;Engineer
          </Box>

          <Fade in timeout={1500}>
            <Stack justifyContent="center" alignItems="center">
              <Avatar
                sx={{
                  margin: 2,
                  height: 150,
                  width: 150,
                  display: 'block',
                }}
                alt="Torie Jenkins"
                src={profilePic}
              />
            </Stack>
          </Fade>
          <Box sx={{ typography: 'p', color: textColor }}>
            Tech&nbsp;enthusiast, dreamer
          </Box>
        </Grid>
      </Grid>
    </Fade>
  </div>
);

export default Splash;

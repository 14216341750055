import React from 'react';

import Grid from '@mui/material/Grid';

import Typer from '../../../components/Typer';

import { type as loop } from '@camwiegert/typical';

const typingSteps = [
  'Thanks for visiting!',
  2000,
  'More content coming soon!',
  5000,
  loop,
];

const spinWheelSteps = ['/', 1000, '-', 1000, '\\', 1000, '-', 1000, loop];

const TypeIntro = () => {
  return (
    <div style={{ minHeight: 600 }}>
      <Grid
        container
        style={{ backgroundColor: 'black', height: '100%', width: '100%' }}
      >
        <Grid style={{ width: 'inherit', height: 'inherit' }} item>
          <Typer
            style={{
              fontSize: 20,
              paddingTop: 200,
              color: '#bdbdbd',
              textAlign: 'center',
            }}
            steps={typingSteps}
          />
          <Typer
            style={{
              paddingTop: 300,
              fontSize: 20,
              color: 'white',
              textAlign: 'center',
            }}
            steps={spinWheelSteps}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TypeIntro;

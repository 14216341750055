/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Box, ThemeProvider } from '@mui/system';

// import styles
import './App.css';

import NavBar from './components/NavBar';
import TabPanel from './components/TabPanel';

import pages from './pages';
import generateTheme from './data/theme';

const App = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const CurrentPage = getCurrentPage(currentTab);

  ['customStyles', 'customProps'].map((x) => {
    if (!CurrentPage[x]) CurrentPage[x] = {};
  });

  const { customStyles, customProps } = CurrentPage;

  const theme = generateTheme(CurrentPage.theme||{});

  const styles = {
    ...defaultStyles,
    ...customStyles,
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles.App}>
        <NavBar
          sx={styles.NavBar}
          currentTab={currentTab}
          handleChange={(e, v) => {
            // reset view on each tab change
            window.scrollTo(0,0);
            setCurrentTab(v);
          }}
          pageLabels={getPageLabels(currentTab)}
          styles={styles}
          {...(customProps.NavBar||{})}
         />
        <TabPanel
          sx={styles.TabPanel}
          value={currentTab}
          index={currentTab}
          {...(customProps.TabPanel||{})}
        >
          <CurrentPage />
        </TabPanel>
      </Box>
    </ThemeProvider>
  );
};

const defaultStyles = {
  TabPanel: {
    paddingTop: 70,
    paddingLeft: 10,
  },
};

const getCurrentPage = (currentTab) => {
  const pageNames = Object.keys(pages);
  const CurrentPage = pages[pageNames[currentTab]];
  return CurrentPage;
};

const getPageLabels = () => {
  const pageNames = Object.keys(pages);
  const pageLabels = pageNames.map((name) =>
    name.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
  );
  return pageLabels;
};

export default App;

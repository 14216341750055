import React, { useRef, useEffect } from 'react';

import { any, array, string } from 'prop-types';

import { type } from '@camwiegert/typical';

const propTypes = {
  className: string,
  children: any,
  steps: array,
};

const defaultProps = {
  className: undefined,
  children: undefined,
  steps: [],
};

const Typer = ({ steps, ...props }) => {
  const r = useRef(null);
  useEffect(() => {
    type(r.current, ...steps);
  }, []);
  return <p {...props} ref={r} />;
};

Typer.propTypes = propTypes;
Typer.defaultProps = defaultProps;

export default Typer;

import React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import ContactCard from '../components/ContactCard';
// import EmailForm from '../components/EmailForm';

import linkedInLogo from '../assets/linkedin.png';
import githubLogo from '../assets/github.png';

const Contact = () => {
  const linkedInProfileUrl = 'https://www.linkedin.com/in/torie-miyen-jenkins/';
  const githubProfileUrl = 'https://github.com/tjenki35';

  return (
    // <Stack sx={{ backgroundColor: '#cfd8dc' }}>
    <Stack>
      <Grid
        // sx={{ marginTop: 10, marginBottom: 4, backgroundColor: '#cfd8dc' }}
        sx={{ marginTop: 10, marginBottom: 4 }}
        alignItems="center"
        justifyContent="center"
        container
        spacing={4}
        direction="row"
        columns={{ xs: 2 }}
      >
        <Grid item>
          <ContactCard
            timing={1000}
            header="Connect on LinkedIn!"
            image={linkedInLogo}
            alt="LinkedIn"
            link={linkedInProfileUrl}
            ctaText="View Profile"
          />
        </Grid>

        <Grid item>
          <ContactCard
            timing={1500}
            header="Check out my code!"
            image={githubLogo}
            alt="LinkedIn"
            link={githubProfileUrl}
            ctaText="View Profile"
          />
        </Grid>
        <Grid item>
          <ContactCard
            timing={2000}
            header="Send me an email!"
            // TODO: add an image 
            // mage={linkedInLogo}
            // alt="LinkedIn"
            link={'mailto:torie.jenkins@toriejenkins.com'}
            ctaText="Open email client"
          />
        </Grid>
      </Grid>

      {/* TODO: add this if we decide to use email functionality (requires backend) <Grid
        sx={{ marginBottom: 10, backgroundColor: '#cfd8dc' }}
        alignItems="center"
        justifyContent="center"
        container
        columns={{ xs: 1 }}
      >
        <Grid item>
          <EmailForm timing={2000} />
        </Grid>
      </Grid> */}
    </Stack>
  );
};

Contact.customStyles = {
  TabPanel: {
    margin: 0,
    padding: 0,
  },
  App: {
    // backgroundColor: '#cfd8dc',
    height: '100%',
  },
  AppBar: {
    // backgroundColor: '#cfd8dc',
    border: 'none',
  },
};

Contact.customProps = {
  NavBar: {
    navColor: 'inherit',
  },
};

export default Contact;

import React from 'react';

import { any, number, string } from 'prop-types';

const propTypes = {
  children: any,
  index: number,
  className: string,
};

const defaultProps = {
  children: undefined,
  index: 0,
  className: '',
};

const TabPanel = (props) => {
  const { children, index, className, ...rest } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...rest}
    >
      {children}
    </div>
  );
};

TabPanel.defaultProps = defaultProps;
TabPanel.propTypes = propTypes;

export default TabPanel;

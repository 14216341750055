import React from 'react';

import Splash from './About/sections/Splash';
import TypeIntro from './About/sections/TypeIntro';

// TODO: It would be nice to have a discrete navigation bar on the right or left.

const About = () => {
  return (
    <>
      <Splash />
      <TypeIntro />
    </>
  );
};

About.customStyles = {
  TabPanel: {
    margin: 0,
    padding: 0,
  },
  App: {
    height: '100%',
  },
  Tab: {
    color: '#bdbdbd',
  },
  AppBar: {
    border: 'none',
  },
};

About.customProps = {
  NavBar: {
    transparent: true,
  },
};

// TODO: Figure out how to apply a custom theme per page
About.theme = {};

// Add a wheel to show all the different languages that I can use.

About.defaultProps = {};

export default About;

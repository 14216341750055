import { createTheme } from '@mui/material';

const generateTheme = (input) => {
  const mainThemeConfiguration = {
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  };

  const theme = createTheme({ ...mainThemeConfiguration, ...(input || {}) });

  // TODO: Responsive font sizes https://mui.com/customization/typography/#responsive-font-sizes
  theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  return theme;
};

export default generateTheme;

import React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { any, string, number } from 'prop-types';

const propTypes = {
  header: string,
  image: any,
  alt: string,
  link: string,
  ctaText: string,
  timing: number,
};

const defaultProps = {
  header: '',
  image: undefined,
  alt: '',
  link: '',
  ctaText: '',
};

const ContactCard = ({ header, image, alt, link, ctaText, timing }) => {
  return (
    <>
      <Fade in timeout={timing || 1500}>
        <Card
          raised
          sx={{
            // TODO: better way to do this?
            borderRadius: 2,
            minWidth: 250,
            maxWidth: 250,
            minHeight: 175,
            maxHeight: 175,
            padding: 3,
          }}
        >
          {image && (<CardMedia
            style={{ height: 60, width: '100%' }}
            component="img"
            image={image}
            alt={alt ? alt + ' service icon' : 'service icon'}
          />)}
          <CardHeader sx={{ textAlign: 'center' }} subheader={header} />
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              sx={{ borderRadius: 1 }}
              variant="outlined"
              onClick={() => {
                window.location.href = link;
              }}
              size="small"
            >
              <Typography sx={{ margin: 0.5 }}> {ctaText} </Typography>
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </>
  );
};

ContactCard.propTypes = propTypes;

ContactCard.defaultProps = defaultProps;

export default ContactCard;
